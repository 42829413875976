import { Button, Dropdown, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";
import {
  LogoMenu,
  MenuHamburgerIcon,
  MenuScanQrIcon,
  MenuSearchIcon,
  ScanCameraIcon,
  ScanGenerateIcon,
  ScanImageIcon,
  TierhubLogo,
} from "src/assets/icons";
import { PATHS } from "src/constants/paths";
import useWindowSize from "src/hooks/useWindowSize";
import { setShowSearchModal } from "src/store/actions/search-modal";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { toastMS } from "../28.toast-message";
import { TIER } from "src/constants/login";
import { EQRCodeType } from "src/constants";
import { qrCodesService } from "src/services/qr-code-service";
import { hasStorageJwtToken } from "src/helpers/storage";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/es/upload";
import useQRImageScanner from "src/hooks/useQRImageScanner";
import { UserState } from "src/store/reducers/user";
import { productManagementService } from "src/services/product-management";
import { useState } from "react";
import SearchGlobal from "../01.layout/search-global";

interface Props {
  isShowMenuButton?: boolean;
  handleMenuButton?: () => void;
  isAnonymous?: boolean;
  handleScanButton?: () => void;
  handleCustomerLeave?: Function;
}
const DefaultHeader = (props: Props) => {
  const { t } = useTranslation();
  const { handleMenuButton, isShowMenuButton, isAnonymous, handleScanButton, handleCustomerLeave } =
    props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLogined = hasStorageJwtToken();
  const { isMobileAndTablet } = useWindowSize();
  const { decodeQRFromUploadedFile } = useQRImageScanner();
  const user: UserState = useSelector((state: any) => state.user);
  const isProductCategoryViewPage = useRouteMatch(PATHS.productCategoryView());

  const [showSearchInput, setShowSearchInput] = useState(false);

  const handleSearchButton = () => {
    // if (location.pathname === PATHS.default() || isProductCategoryViewPage) {
    //   dispatch(setShowSearchModal());
    // }
    setShowSearchInput(true);
  };

  const isShowSearchInput =
    !isAnonymous && (isProductCategoryViewPage || location.pathname === PATHS.default());

  const pathname = location.pathname;

  const isShowQrScanButton =
    pathname === PATHS.default() ||
    pathname.includes(PATHS.map()) ||
    pathname === PATHS.listQRCode() ||
    pathname === PATHS.internalManagement() ||
    pathname === PATHS.logisticManagement() ||
    pathname === PATHS.productCategory() ||
    isProductCategoryViewPage;
  // !isAnonymous &&
  // (location.pathname === PATHS.default() ||
  //   location.pathname === PATHS.productCategory() ||
  //   isProductCategoryViewPage);

  const scanOption = [
    {
      title: "Scan QR Code",
      icon: ScanCameraIcon,
      disabledTier: [],
      onClick: () => history.push(PATHS.qrScan()),
    },
    {
      title: "Generate New QR Code",
      icon: ScanGenerateIcon,
      disabledTier: [TIER.LOGISTIC_PARTNER, TIER.DISTRIBUTOR, TIER.CUSTOMER],
      onClick: async () => {
        try {
          const res = await qrCodesService.createQrCodes({
            numberOfQrCodes: 1,
            type: EQRCodeType.LOT,
          });
          if (Array.isArray(res)) {
            history.push(`${PATHS.productManagement()}/${res[0]}`);
            toastMS("success", t("msg.qrCodeCreated"));
          }
        } catch (error) {
          toastMS("error", "Create Qr Code failed");
        }
      },
    },
    {
      type: "upload",
      title: t("scan.scanQRCodeFromImage"),
      icon: ScanImageIcon,
      disabledTier: [],
      onClick: () => {},
    },
  ];

  const onScanImage = async (info: UploadChangeParam<UploadFile<any>>) => {
    try {
      if (
        info?.file.originFileObj &&
        (info.file.status === "done" || info.file.status === "error")
      ) {
        const decodedQR = await decodeQRFromUploadedFile(info?.file.originFileObj);
        if (decodedQR) {
          let QR_URL: string;
          if (decodedQR.includes("/")) {
            const segments = decodedQR.split("/");
            const productId = segments[segments.length - 1];
            QR_URL = productId;
          } else {
            QR_URL = decodedQR;
          }
          if (user?.myTier === TIER.CUSTOMER) {
            await productManagementService.getProductByQrCode(QR_URL, { isScan: true });
            history.push(`${PATHS.productView()}/${QR_URL}`);
            return;
          }
          if (isLogined) history.push(`${PATHS.productManagement()}/${QR_URL}`);
          else history.push(`${PATHS.productView()}/${QR_URL}`);
        } else {
          toastMS("error", t("msg.noScanResultsFromUploadedImage"));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div className="default-header">
      <div className="default-header__wrapperLogo">
        <img
          src={isMobileAndTablet ? LogoMenu : TierhubLogo}
          alt="login-background"
          onClick={() => {
            handleCustomerLeave?.();
            history.push(PATHS.default());
          }}
        />
        <div className="ver-divider"></div>
        <p>{t("slogan")}</p>
      </div>
      <div className="default-header__right">
        <>
          {showSearchInput ? (
            <SearchGlobal onSearch={() => setShowSearchInput(false)} />
          ) : (
            <Button
              className="default-header__search-btn"
              type="text"
              icon={<MenuSearchIcon />}
              onClick={handleSearchButton}
            />
          )}

          {isShowQrScanButton && (
            <Dropdown
              trigger={["click"]}
              overlayClassName="scan-option-dropdown"
              dropdownRender={() => (
                <div className="scan-option-list">
                  {scanOption.map((item) => {
                    if ((item.disabledTier as any).includes(user.myTier)) return null;

                    const optionContent = (
                      <div className="scan-option" onClick={item.onClick}>
                        <img src={item.icon} alt="scan icon" className="scan-icon" />
                        <span className="scan-title">{item.title}</span>
                      </div>
                    );

                    if (item?.type !== "upload") return optionContent;
                    else
                      return (
                        <Upload onChange={onScanImage} showUploadList={false}>
                          {optionContent}
                        </Upload>
                      );
                  })}
                </div>
              )}
            >
              <MenuScanQrIcon className="scan-qr-icon" />
            </Dropdown>
          )}
        </>
        {isShowMenuButton ? (
          <Button
            className="default-header__menu-btn"
            type="text"
            icon={<MenuHamburgerIcon />}
            onClick={handleMenuButton}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DefaultHeader;
