import { StarFilled } from "@ant-design/icons";
import { Divider, Dropdown, Image, Input, Space } from "antd";
import { debounce, get } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ArrowLeftIcon, DefaultImage, SearchIcon2 } from "src/assets/icons";
import OverflowTooltip from "src/components/34.overflow-tooltip";
import { TIER } from "src/constants/login";
import { PATHS } from "src/constants/paths";
import { CategoryService } from "src/services/category-service";
import { GroupServices } from "src/services/groups-service";
import { ProductManagementService } from "src/services/product-management";
import { ICategory } from "src/types/categories";
import "./styles.scss";

const NO_DATA = "TBD";

enum ESearchSection {
  GROUP = "GROUP",
  PRODUCT = "PRODUCT",
  SUBCATEGORY = "SUBCATEGORY",
}

interface IProps {
  onSearch?: () => void;
}

const SearchGlobal: React.FC<IProps> = ({ onSearch }) => {
  const groupService = new GroupServices();
  const categoryService = new CategoryService();
  const productService = new ProductManagementService();
  const dropdownRef = useRef();
  const inputRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [openDropDown, setOpenDropDown] = useState(false);
  const [viewDetail, setViewDetail] = useState<ESearchSection>();
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [productList, setProductList] = useState<Product[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ICategory[]>([]);

  useEffect(() => {
    clearSearchGlobal();
  }, [location.pathname]);

  useEffect(() => {
    handleSearchChange(searchValue);
  }, [searchValue]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clearSearchGlobal = () => {
    setSearchText("");
    setOpenDropDown(false);
  };

  const handleSearchChange = async (value: string) => {
    // const value = e.target.value || "";
    if (value.length < 3) {
      setOpenDropDown(false);
      return;
    }
    setViewDetail(undefined);

    const searchParams = {
      sortBy: "name",
      direction: "ASC" as any,
      search: value,
    };
    const [productResults, groupResults, subcategoryResults] = await Promise.all([
      productService.searchGlobalProduct(searchParams),
      groupService.searchGroupHomepage(searchParams),
      categoryService.getSubCategories(searchParams),
    ]);

    setGroupList(get(groupResults, "data", []));
    setProductList(get(productResults, "data", []));
    setSubCategoryList(get(subcategoryResults, "data", []));
    setOpenDropDown(true);
  };

  const handleSearchChangeDebounce = debounce(
    (e: any) => setSearchValue(e.target.value || ""),
    1500,
  );

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef?.current &&
      !(dropdownRef?.current as any)?.contains(event.target) &&
      inputRef?.current &&
      !(inputRef?.current as any)?.contains(event.target)
    ) {
      setOpenDropDown(false);
    }
  };

  const renderProductList = (displayAll: boolean) => {
    const resultLength = (productList || []).length;
    return (
      <div className="search-section">
        {!displayAll ? (
          <div className="section-header">
            <h2 className="section-title">Products</h2>
            {resultLength > 3 && (
              <div className="section-action" onClick={() => setViewDetail(ESearchSection.PRODUCT)}>
                See all
              </div>
            )}
          </div>
        ) : (
          <div className="section-result" onClick={() => setViewDetail(undefined)}>
            <ArrowLeftIcon />
            <div>
              Found <span>{resultLength}</span> products
            </div>
          </div>
        )}
        {(productList || []).map((product, index) => {
          if (!displayAll && index > 2) return null;
          return (
            <div
              key={index}
              className="section-item"
              onClick={() => {
                clearSearchGlobal();
                history.push(`${PATHS.productView()}/${product?.qrCodeId}`);
              }}
            >
              <Image
                src={product?.productMedias[0]?.thumbnail || product?.productMedias[0]?.link || ""}
                preview={false}
                alt="logo"
                className="item-logo"
                fallback={DefaultImage}
              />
              <Space size={0} direction="vertical">
                <Space size={6} align="center">
                  <OverflowTooltip title={product?.name}>
                    <div className="item-name">{product?.name || NO_DATA}</div>
                  </OverflowTooltip>
                  <div className="item-rate">
                    <StarFilled className="rate-icon" />
                    <span className="rate-text">{product?.rate || "0"}</span>
                  </div>
                </Space>
                <Space align="center" size={8}>
                  <OverflowTooltip title={product?.category?.name}>
                    <div className="item-type">{product?.category?.name || NO_DATA}</div>
                  </OverflowTooltip>
                  <Divider type="vertical" />
                  <div className="item-type">PRODUCT ID: {product?.id || NO_DATA}</div>
                </Space>
              </Space>
            </div>
          );
        })}
      </div>
    );
  };

  const renderGroupList = (displayAll: boolean) => {
    const resultLength = (groupList || []).length;
    return (
      <div className="search-section">
        {!displayAll ? (
          <div className="section-header">
            <h2 className="section-title">Supplier, Restaurant & Store</h2>
            {resultLength > 3 && (
              <div className="section-action" onClick={() => setViewDetail(ESearchSection.GROUP)}>
                See all
              </div>
            )}
          </div>
        ) : (
          <div className="section-result" onClick={() => setViewDetail(undefined)}>
            <ArrowLeftIcon />
            <div>
              Found <span>{resultLength}</span> results
            </div>
          </div>
        )}
        {(groupList || []).map((item, index) => {
          if (!displayAll && index > 2) return null;
          return (
            <div
              key={index}
              className="section-item"
              onClick={() => {
                clearSearchGlobal();
                if (item?.tierType === TIER.FACTORY)
                  history.push(`${PATHS.producerDetail()}/${item?.id}`);
                else history.push(`${PATHS.restaurantDetail()}/${item?.id}`);
              }}
            >
              <Image
                src={item?.logo || ""}
                preview={false}
                alt="logo"
                className="item-logo"
                fallback={DefaultImage}
              />
              <Space size={0} direction="vertical">
                <Space size={6} align="center">
                  <OverflowTooltip title={item?.name}>
                    <div className="item-name">{item?.name || NO_DATA}</div>
                  </OverflowTooltip>
                </Space>
                <Space align="center" size={8}>
                  <div className="item-type">
                    {item?.tierType === TIER.FACTORY ? "Producer" : "Store"}
                  </div>
                </Space>
              </Space>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSubcategoryList = (displayAll: boolean) => {
    const resultLength = (subCategoryList || []).length;
    return (
      <div className="search-section">
        {!displayAll ? (
          <div className="section-header">
            <h2 className="section-title">Seafoods</h2>
            {resultLength > 3 && (
              <div
                className="section-action"
                onClick={() => setViewDetail(ESearchSection.SUBCATEGORY)}
              >
                See all
              </div>
            )}
          </div>
        ) : (
          <div className="section-result" onClick={() => setViewDetail(undefined)}>
            <ArrowLeftIcon />
            <div>
              Found <span>{resultLength}</span> results
            </div>
          </div>
        )}
        {(subCategoryList || []).map((item, index) => {
          if (!displayAll && index > 2) return null;
          return (
            <div
              key={index}
              className="section-item"
              onClick={() => {
                clearSearchGlobal();
                history.push(`${PATHS.subcategoryDetail()}/${item?.id}`);
              }}
            >
              <Image
                src={item?.logo || ""}
                preview={false}
                alt="logo"
                className="item-logo"
                fallback={DefaultImage}
              />
              <Space size={0} direction="vertical">
                <Space size={6} align="center">
                  <OverflowTooltip title={item?.name}>
                    <div className="item-name">{item?.name || NO_DATA}</div>
                  </OverflowTooltip>
                </Space>
                <Space align="center" size={8}>
                  <div className="item-type">{(item as any)?.parentCategory?.name || NO_DATA}</div>
                </Space>
              </Space>
            </div>
          );
        })}
      </div>
    );
  };

  const renderResults = () => {
    const renderContent = () => {
      switch (viewDetail) {
        case ESearchSection.PRODUCT:
          return renderProductList(true);
        case ESearchSection.GROUP:
          return renderGroupList(true);
        case ESearchSection.SUBCATEGORY:
          return renderSubcategoryList(true);
        default:
          return null;
      }
    };

    const productLength = (productList || []).length;
    const groupLength = (groupList || []).length;
    const subCategoryLength = (subCategoryList || []).length;

    if (viewDetail) return <div className="search-list">{renderContent()}</div>;
    if (!productLength && !groupLength && !subCategoryLength)
      return <div className="no-results">No results</div>;
    return (
      <div>
        <div className="search-list">{!!productLength && renderProductList(false)}</div>
        <div className="search-list">{!!subCategoryLength && renderSubcategoryList(false)}</div>
        <div className="search-list">{!!groupLength && renderGroupList(false)}</div>
      </div>
    );
  };

  return (
    <div ref={dropdownRef as any} className="global-search">
      <Dropdown
        open={openDropDown}
        dropdownRender={() => <div ref={inputRef as any}>{renderResults()}</div>}
        overlayClassName="global-search-dropdown"
        getPopupContainer={(triggerNode: any) => {
          if (triggerNode?.className?.includes("ant-tooltip-open")) {
            return window.document.body;
          }
          return triggerNode || window.document.body;
        }}
      >
        <Input
          size="large"
          allowClear
          value={searchText}
          suffix={
            <img
              className="icon-search"
              src={SearchIcon2}
              style={{ width: "26px", height: "26px" }}
              alt="search-icon"
              onClick={onSearch}
            />
          }
          placeholder="Search product"
          onChange={(e: any) => {
            setSearchText(e.target.value || "");
            handleSearchChangeDebounce(e);
          }}
          className={"input-search"}
        />
      </Dropdown>
    </div>
  );
};

export default SearchGlobal;
